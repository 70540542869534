import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { initializeStore } from 'src/server';

export default function ClientWrapper({ children, initialState }) {
  return <Provider store={initializeStore(initialState)}>{children}</Provider>;
}

ClientWrapper.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.any,
};
