// store/bannerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { post } from '../http';

// Thunk to fetch reviews
export const fetchReview = createAsyncThunk(
  'banner/fetchReview',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/review/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const createReview = createAsyncThunk(
  'review/create',
  async ({ state, handleClear, handleClose, enqueueSnackbar }, { rejectWithValue }) => {
    try {
      const response = await post(`/review/user/new`, state);
      if (response.status === 2000) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        enqueueSnackbar(`Review created successfully`, {
          variant: 'success',
        });
        return response.data;
      }
      return enqueueSnackbar(response.data.message, {
        variant: 'error',
      });
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const removeReview = createAsyncThunk(
  'review/remove',
  async ({ reviewId, onClosePopup, enqueueSnackbar }, { rejectWithValue }) => {
    try {
      const response = await post(`/review/user/delete/${reviewId}`);
      if (response.status === 2000) {
        if (onClosePopup) {
          onClosePopup();
        }
        enqueueSnackbar('Review Deleted', {
          variant: 'success',
        });
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
const reviewSlice = createSlice({
  name: 'reviews',
  initialState: {
    loading: false,
    error: null,
    reviews: [],
    deleteReviewLoading: false,
  },
  reducers: {
    clearReviewState: (state) => {
      state.reviews = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReview.fulfilled, (state, action) => {
        state.loading = false;
        state.reviews = action.payload;
        state.error = null;
      })
      .addCase(fetchReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createReview.pending, (state) => {
        state.loading = true;
      })
      // .addCase(createReview.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.reviews = action.payload;
      //   state.error = null;
      // })
      .addCase(createReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews || [];
        const reviews = jsonState?.reviews || [];

        state.reviews = {
          ...state.reviews,
          reviews: [action.payload, ...reviews],
        };
        state.createAddressLoading = false;
        state.error = null;
      })
      .addCase(createReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(removeReview.pending, (state) => {
        state.deleteReviewLoading = true;
      })
      .addCase(removeReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews || [];
        const reviews = jsonState?.reviews || [];

        state.reviews = {
          ...state.reviews,
          reviews: reviews?.filter((item) => item?.uid !== action.payload?.uid),
        };
        state.deleteReviewLoading = false;
        state.error = null;
      })
      .addCase(removeReview.rejected, (state, action) => {
        state.deleteReviewLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearBannerState } = reviewSlice.actions;

export default reviewSlice.reducer;
