import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { env } from 'src/server/http';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (event) => {
      setHasError(true);
      console.error('Error caught by ErrorBoundary:', event.error);
    };

    window.addEventListener('error', errorHandler);

    return () => window.removeEventListener('error', errorHandler);
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     route.push(paths.public.home);
  //   }, 5000);
  // }, []);

  if (hasError && env === 'prod') {
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>Oops! Something went wrong.</h1>
        <p style={styles.message}>
          We’re sorry, but it seems that an unexpected error has occurred. Our team has been
          notified, and we’re working to resolve the issue.
        </p>
        <p style={styles.suggestions}>Here are a few things you can try:</p>
        <ul style={styles.list}>
          <li>Refresh the page.</li>
          <li>Clear your browser cache and try again.</li>
          <li>If the problem persists, please contact our support team.</li>
        </ul>
        <p style={styles.contact}>
          <strong>Support:</strong> +91 90484 40022
        </p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

const styles = {
  container: {
    padding: '2rem',
    textAlign: 'start',
    color: '#333',
    width: 'fit-content',
    margin: 'auto',
    height: '80vh',
    display: 'flex',
    alginItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.75rem',
    color: '#d9534f',
  },
  message: {
    fontSize: '1rem',
    marginTop: '1rem',
    maxWidth: '500px',
  },
  suggestions: {
    fontSize: '1rem',
    marginTop: '1rem',
    fontWeight: 'bold',
  },
  list: {
    textAlign: 'left',
    display: 'inline-block',
    marginTop: '0.5rem',
  },
  contact: {
    fontSize: '1rem',
    marginTop: '1.5rem',
  },
};
