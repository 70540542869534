import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import 'src/global.css';
// i18n
import 'src/locales/i18n';
import 'src/theme/scss/custom.scss';

import ThemeProvider from './theme';
import ClientWrapper from './server/useStore';
import { LocalizationProvider } from './locales';
import { SnackbarProvider } from './components/snackbar';
import { SettingsProvider } from './components/settings';
import { MotionLazy } from './components/animate/motion-lazy';
import { OfflineProvider } from './providers/OfflineProvider';
import { StyledProgressBar } from './components/progress-bar';
import { CurrencyProvider } from './providers/CurrencyProvider';
import ErrorBoundary from './components/ErrorBoundary/error-boundary';

export default function App() {
  useScrollToTop();

  return (
    <ErrorBoundary>
      <ClientWrapper>
        <LocalizationProvider>
          <CurrencyProvider>
            <OfflineProvider>
              <SettingsProvider
                defaultSettings={{
                  themeMode: 'light', // 'light' | 'dark'
                  themeDirection: 'ltr', //  'rtl' | 'ltr'
                  themeContrast: 'default', // 'default' | 'bold'
                  themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                  themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                  themeStretch: false,
                }}
              >
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <StyledProgressBar />
                      <Router />
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </OfflineProvider>
          </CurrencyProvider>
        </LocalizationProvider>
      </ClientWrapper>
    </ErrorBoundary>
  );
}
