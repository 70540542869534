import Storage from 'src/utils/localStorage';

export const useSetToken = (TOKEN_PREFIX, token) => {
  Storage.set(TOKEN_PREFIX, token);
};

export const useGetToken = (key) => {
  const token = Storage.get(key);
  return {
    isSuccess: !!token,
    token,
  };
};

export const useSetRole = (role, name) => {
  const currentRole = role;
  if (currentRole) {
    Storage.set('currentRole', currentRole);
    Storage.set('user', name);
  }
};

export const useClearToken = (key) => {
  Storage.clear(key);
  Storage.clear('is_guest');
};

export const useGetUser = () => {
  const user = Storage.get('user');
  return user;
};

export const useSetEncryptLocalData = (key, value) => {
  const encrypted = value;
  Storage.set(key, encrypted);
};

export const useClearStorage = () => {
  Storage.clear();
};
