import { Navigate, useRoutes } from 'react-router-dom';

import { authRoutes, publicRoutes } from './routes';


export default function Router() {

  return useRoutes([
    ...publicRoutes,
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
