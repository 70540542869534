import { Box, Stack } from '@mui/system';
import { List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function OfflinePage() {
  return (
    <Box sx={{ pt: 5, px: 5, height: '100vh' }}>
      <Box mb={5}>
        <Logo />
      </Box>
      <Stack alignItems="center" justifyContent="center" sx={{}}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          You Are Offline
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          It seems that you have lost your internet connection.
          <br /> Please check your network settings or try again later.
        </Typography>
        <Box sx={{ mt: 3, mb: 3, px: 1 }}>
          <Typography sx={{ color: 'text.secondary', textAlign: 'start' }}>
            Here are some things you can try:
          </Typography>

          <List sx={{ padding: 0 }}>
            <ListItem>✔️ Check your Wi-Fi or mobile data.</ListItem>
            <ListItem>✔️ Restart your router.</ListItem>
            <ListItem>✔️ Refresh the page once you are back online.</ListItem>
          </List>
        </Box>
      </Stack>
    </Box>
  );
}
