import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Image = forwardRef(
  ({ alt, src, loading = 'lazy', sx, width = 100, height = 100, className, imageStyle }) => (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        position: 'relative',
        ...sx,
      }}
      className={className}
    >
      <img
        alt={alt}
        src={src}
        loading={loading} // eager
        width={width}
        height={height}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          ...imageStyle,
        }}
      />
    </Box>
  )
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.object,
  imageStyle: PropTypes.object,
};

export default Image;
