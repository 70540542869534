// store/bannerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { post } from '../http';

// Thunk to fetch banners
export const fetchBanners = createAsyncThunk(
  'banner/fetchBanners',
  async ({ state }, { rejectWithValue }) => {
    try {
      const module = `${state?.module}${state?.module_id ? state?.module_id : ''}`;
      const cachedData = getFromStorage(module);

      if (cachedData?.timestamp) {
        return cachedData;
      }

      const response = await post(`/banner/user/module/all`, state);
      if (response.status === 2000) {
        saveToStorage(module, response.data);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    loading: false,
    error: null,
    banners: [],
    cache: {},
  },
  reducers: {
    clearBannerState: (state) => {
      state.banners = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;

        state.error = null;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearBannerState } = bannerSlice.actions;

export default bannerSlice.reducer;
