import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useContext, createContext } from 'react';

import Storage from 'src/utils/localStorage';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const localState = Storage?.get('site-currency');

  const defaultCurrency = localState || {
    conversion_rate_from_omr: 1,
    symbol: 'OMR',
    title: { arabic: 'ريال عماني', english: 'OMR' },
    uid: '2a3b3ec5-2541-4b5d-88f4-6240fdca3c3d',
  };

  const [currency, setCurrency] = useState(defaultCurrency); // en ar

  const changeCurrency = (cur) => {
    setCurrency(cur);
    Storage.set('site-currency', cur);
  };

  useEffect(() => {
    if (!localState) {
      changeCurrency(currency);
    }
  }, [currency, localState]);

  const value = useMemo(() => ({ currency, changeCurrency }), [currency]);

  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};

export const useCurrency = () => useContext(CurrencyContext);

CurrencyProvider.propTypes = {
  children: PropTypes.any,
};
