import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';

import { useGetToken } from 'src/hooks/useHandleSessions';
import { useLoginPathname } from 'src/hooks/useLoginPathname';

import { TOKEN_PREFIX } from 'src/server/http';

import { SplashScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return <>{!isMounted ? <SplashScreen /> : <Container> {children}</Container>}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

function Container({ children }) {
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const loginPathname = useLoginPathname();
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);

  const check = useCallback(() => {
    if (!isSuccess && !token) {
      const href = loginPathname;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isSuccess, loginPathname, router, token]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

Container.propTypes = {
  children: PropTypes.node,
};
