// store/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { get, post } from '../http';

// Thunk to fetch all collections
export const fetchCollections = createAsyncThunk(
  'collection/fetchCollection',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/collection/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetch single collection
export const fetchCollectionSingleView = createAsyncThunk(
  'collection/single',
  async ({ name, state }, { rejectWithValue }) => {
    try {
      const cachedData = getFromStorage(name);
      if (cachedData?.timestamp) {
        return cachedData;
      }
      const response = await get(`/collection/user/single/${name}`, state);
      if (response.status === 2000) {
        saveToStorage(name, response.data);

        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const collectionSlice = createSlice({
  name: 'collection',
  initialState: {
    loading: false,
    error: null,
    collections: [],
    collectionDetails: {},
  },
  reducers: {
    clearCollectionState: (state) => {
      state.collections = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.loading = false;
        state.collections = action.payload;
        state.error = null;
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchCollectionSingleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCollectionSingleView.fulfilled, (state, action) => {
        state.loading = false;
        state.collectionDetails = action.payload;
        state.error = null;
      })
      .addCase(fetchCollectionSingleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearCollectionState } = collectionSlice.actions;

export default collectionSlice.reducer;
