/**
 * Save data to storage (localStorage or sessionStorage) with an expiration time.
 * @param {string} key - The key to store the data under.
 * @param {any} value - The value to store.
 * @param {number} ttl - Time to live in milliseconds (e.g., 3600000 for 1 hour).
 * @param {boolean} useSession - Use sessionStorage instead of localStorage.
 */
import Storage from './localStorage';

export const saveToStorage = (key, value) => {
  const timestamp = new Date().getTime() + 300000;
  const payload = { ...value, timestamp };

  try {
    Storage.set(key, payload);
  } catch (error) {
    console.error('Error saving to storage:', error);
  }
};

/**
 * Get data from storage if it's still valid (not expired).
 * @param {string} key - The key to retrieve the data from.
 * @param {boolean} useSession - Use sessionStorage instead of localStorage.
 * @returns {any|null} - The stored data, or null if expired or not found.
 */

export const getFromStorage = (key) => {
  const parsedItem = Storage.get(key);

  if (!parsedItem) return null;

  try {
    const currentTime = new Date().getTime();

    if (currentTime > parsedItem.timestamp) {
      Storage.clear(key);
      return null;
    }

    return parsedItem;
  } catch (error) {
    console.error('Error reading from storage:', error);
    return null;
  }
};
