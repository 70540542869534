// store/groupSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { post } from '../http';

// Thunk to fetch groups
export const fetchGroups = createAsyncThunk(
  'group/fetchGroups',
  async ({ page, limit, state }, { rejectWithValue }) => {
    try {
      const cacheKey = `groups_page_${page}`;
      const cachedData = getFromStorage(cacheKey);

      if (cachedData?.timestamp) {
        return cachedData;
      }

      // Fetch data from the API
      const response = await post(`/group/user/all?page=${page}&limit=${limit}`, state);

      if (response.status === 2000) {
        // Cache the new data
        saveToStorage(cacheKey, response.data);

        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// export const fetchGroups = createAsyncThunk(
//   'group/fetchGroups',
//   async ({ page, limit, state }, { rejectWithValue }) => {
//     try {
//       const response = await post(`/group/user/all?page=${page}&limit=${limit}`, state);
//       if (response.status === 2000) {
//         return response.data;
//       }
//       return rejectWithValue(response.message || 'Something went wrong');
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
//   }
// );

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    loading: false,
    error: null,
    groups: [],
  },
  reducers: {
    cleargroupState: (state) => {
      state.groups = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
        state.error = null;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { cleargroupState } = groupSlice.actions;

export default groupSlice.reducer;
