// store/brandSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { get, post } from '../http';

// Thunk to fetch brands
export const fetchBrands = createAsyncThunk(
  'brand/fetchBrands',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/brand/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchBrandSingleView = createAsyncThunk(
  'brand/fetchBrandSingleView',
  async ({ name }, { rejectWithValue }) => {
    try {
      const cachedData = getFromStorage(name);

      if (cachedData?.timestamp) {
        return cachedData;
      }
      const response = await get(`/brand/user/single/${name}`);
      if (response.status === 2000) {
        saveToStorage(name, response.data);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchBrandsByHierarchy = createAsyncThunk(
  'brand/fetchBrandsByHierarchy',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/brand/user/hierarchy/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    loading: false,
    error: null,
    brands: [],
    brandSingleView: {},
    brandsByHierarchy: [],
  },
  reducers: {
    clearBrandState: (state) => {
      state.brands = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload;
        state.error = null;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchBrandSingleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrandSingleView.fulfilled, (state, action) => {
        state.loading = false;
        state.brandSingleView = action.payload;
        state.error = null;
      })
      .addCase(fetchBrandSingleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchBrandsByHierarchy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrandsByHierarchy.fulfilled, (state, action) => {
        state.loading = false;
        state.brandsByHierarchy = action.payload;
        state.error = null;
      })
      .addCase(fetchBrandsByHierarchy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearBrandState } = brandSlice.actions;

export default brandSlice.reducer;
