import { configureStore } from '@reduxjs/toolkit';

import userReducer from './slice/userSlice';
import groupReducer from './slice/groupSlice';
import brandReducer from './slice/brandSlice';
import bannerReducer from './slice/bannerSlice';
import reviewReducer from './slice/reviewSlice';
import authReducer from './slice/authentication';
import orderReducer from './slice/cartOrderSlice';
import productReducer from './slice/productSlice';
import settingsReducer from './slice/settingsSlice';
import categoriesReducer from './slice/categorySlice';
import collectionReducer from './slice/collectionSlice';

export function initializeStore(initialState) {
  return configureStore({
    reducer: {
      product: productReducer,
      group: groupReducer,
      brand: brandReducer,
      categories: categoriesReducer,
      collections: collectionReducer,
      banners: bannerReducer,
      settings: settingsReducer,
      reviews: reviewReducer,
      auth: authReducer,
      profile: userReducer,
      order: orderReducer,
    },
    preloadedState: initialState,
  });
}
