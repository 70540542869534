class Storage {
  static set(key, cartItems) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON?.stringify(cartItems));
    }
  }

  static get(key) {
    if (key && typeof window !== 'undefined') {
      return JSON?.parse(localStorage.getItem(key));
    }
    return null;
  }

  static clear(key) {
    if (typeof window !== 'undefined') {
      return localStorage.clear(key);
    }
    return null;
  }
}

export default Storage;
