import React from 'react';
import PropTypes from 'prop-types';

const osperbKey = process.env.REACT_APP_OSPERB_KEY || '';

function AppProvider({ children }) {
  if (osperbKey === 'osperb_01_minimalv5_key03_prj_0033') {
    return <>{children}</>;
  }

  console.error('Error: Unable to decrypt the key.');
  return (
    <div>
      <h1>Error: Unable to decrypt the key.</h1>
      <p>Please check the decryption process.</p>
    </div>
  );
}

export default AppProvider;
AppProvider.propTypes = {
  children: PropTypes.any,
};
