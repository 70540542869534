import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import OfflinePage from 'src/pages/error/OfflinePage';

export const OfflineProvider = ({ children }) => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      const updateOnlineStatus = () => {
        setIsOffline(!navigator.onLine);
      };

      // Set initial offline status
      updateOnlineStatus();

      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);

      // Cleanup the event listeners on unmount
      return () => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
      };
    }
    return '';
  }, []);

  return <>{isOffline ? <OfflinePage /> : children}</>;
};

OfflineProvider.propTypes = {
  children: PropTypes.any,
};
