import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import Image from '../image';
import OspLink from '../link/osp-link';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ isFooter, isFav, disabledLink = false, sx, ...other }, ref) => {
  const getImageSrc = () => {
    if (isFooter) {
      return '/assets/images/logs/footer-logo.webp';
    }
    if (isFav) {
      return '/assets/images/logs/favIcon.png';
    }
    return '/assets/images/logs/logo.webp';
  };

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        maxWidth: isFooter ? 150 : 300,
        maxHeight: isFooter ? 38 : 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <Image
        alt="buildex-logo"
        src={getImageSrc()}
        disabledEffect
        sx={{ borderRadius: 1, aspectRatio: 1 / 1, objectFit: 'contain' }}
      />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <OspLink component={RouterLink} href={paths.public.home} sx={{ display: 'contents' }}>
      {logo}
    </OspLink>
  );
});

Logo.displayName = 'Logo';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  isFav: PropTypes.bool,
  sx: PropTypes.object,
  isFooter: PropTypes.any,
};

export default Logo;
