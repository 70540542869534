import { paths } from 'src/routes/paths';

export const useLoginPathname = () => {
  const searchParams = new URLSearchParams({
    returnTo: window.location.pathname,
  }).toString();

  const loginPath = paths.public.login;

  const loginPathname = `${loginPath}?${searchParams}`;

  return loginPathname;
};
