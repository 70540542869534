// store/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { get, post } from '../http';

// Thunk to fetch all category
export const fetchCategories = createAsyncThunk(
  'category/fetchCategory',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/category/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetch All Categories By Hierarchy
export const fetchCategoriesByHierarchy = createAsyncThunk(
  'category/fetchCategoryByHierarchy',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/category/user/hierarchy/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetch All Categories By Hierarchy
export const fetchCategoriesByHierarchyHome = createAsyncThunk(
  'category/fetchCategoryByHierarchy-home',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const cacheKey = `category_hierarchy_home_${page}`;
      const cachedData = getFromStorage(cacheKey);

      if (cachedData?.timestamp) {
        return cachedData;
      }

      const response = await post(
        `/category/user/hierarchy/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        saveToStorage(cacheKey, response.data);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetch single category
export const fetchCategoryDetail = createAsyncThunk(
  'category/single',
  async ({ categoryId, state }, { rejectWithValue }) => {
    try {
      const response = await get(`/category/user/single/${categoryId}`, state);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    loading: false,
    error: null,
    categories: [],
    categoriesByHierarchy: [],
    categoriesByHierarchyHome: [],
    categoryDetails: {},
    cache: {},
  },
  reducers: {
    clearCategoryState: (state) => {
      state.categories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchCategoriesByHierarchy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoriesByHierarchy.fulfilled, (state, action) => {
        state.loading = false;
        state.categoriesByHierarchy = action.payload;

        state.error = null;
      })
      .addCase(fetchCategoriesByHierarchy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchCategoriesByHierarchyHome.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoriesByHierarchyHome.fulfilled, (state, action) => {
        state.loading = false;
        state.categoriesByHierarchyHome = action.payload;

        state.error = null;
      })
      .addCase(fetchCategoriesByHierarchyHome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchCategoryDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryDetails = action.payload;
        state.error = null;
      })
      .addCase(fetchCategoryDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearCategoryState } = categorySlice.actions;

export default categorySlice.reducer;
