import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/paths';

import { useSetToken, useClearToken } from 'src/hooks/useHandleSessions';

import Storage from 'src/utils/localStorage';

// eslint-disable-next-line import/no-cycle
import { fetchUser } from './userSlice';
import { post, TOKEN_PREFIX } from '../http';

// Thunk to fetch banners
export const loginUser = createAsyncThunk(
  'login/user',
  async ({ state, setOtp, setSeconds, enqueueSnackbar }, { rejectWithValue }) => {
    try {
      const response = await post(`/auth/user/login`, state);
      if (response.status === 2000) {
        if (setOtp) {
          setOtp(true);
        }
        if (setSeconds) {
          setSeconds(60);
        }
        enqueueSnackbar(`OTP Sent to ${state?.phone}`, {
          variant: 'info',
        });
        return response.data;
      }
      return enqueueSnackbar(response.data.message, {
        variant: 'error',
      });
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'otp/verify',
  async ({ state, route, path, enqueueSnackbar }, { rejectWithValue }) => {
    try {
      const response = await post(`/auth/user/verify-otp`, state);
      const isLocalCart = localStorage.getItem('isLocalCart');

      if (response.status === 2000) {
        useSetToken(TOKEN_PREFIX, response?.data?.token);
        if (!isLocalCart) {
          route.push(path);
        }
        enqueueSnackbar(`Login Successfully`, {
          variant: 'success',
        });
        return response?.data;
      }
      if (response.status === 2001) {
        enqueueSnackbar(response.message, {
          variant: 'error',
        });
      }

      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const logOutUser = createAsyncThunk(
  'logout/user',
  async ({ enqueueSnackbar, closeAccountData, dispatch, route }, { rejectWithValue }) => {
    try {
      const response = await post(`/auth/user/logout`);
      enqueueSnackbar(`Logout Successfully`, {
        variant: 'success',
      });
      if (closeAccountData) {
        closeAccountData();
      }
      useClearToken(TOKEN_PREFIX);
      dispatch(fetchUser());
      if (route) {
        route.push(paths.public.home);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const guestUserLogin = createAsyncThunk(
  'login/guest',
  async ({ enqueueSnackbar, path, route }, { rejectWithValue }) => {
    try {
      const response = await post(`/auth/user/guest/login`);
      const isLocalCart = localStorage.getItem('isLocalCart');
      if (response.status === 2000) {
        useSetToken(TOKEN_PREFIX, response?.data?.token);
        Storage.set('is_guest', true);
        enqueueSnackbar(`Login Successfully`, {
          variant: 'success',
        });

        if (!isLocalCart) {
          route.push(path);
        }
        return response.data;
      }
      return enqueueSnackbar(response.data.message, {
        variant: 'error',
      });
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const authenticationSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    loginData: {},
    otpData: {},
    guestUserLoading: false,
    loginPopup: false,
  },
  reducers: {
    clearBannerState: (state) => {
      state.banners = [];
    },
    triggerLoginPopup: (state, action) => {
      state.loginPopup = action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.loginData = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.otpData = action.payload;
        state.error = null;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(guestUserLogin.pending, (state) => {
        state.guestUserLoading = true;
      })
      .addCase(guestUserLogin.fulfilled, (state, action) => {
        state.guestUserLoading = false;
        state.loginData = action.payload;
        state.error = null;
      })
      .addCase(guestUserLogin.rejected, (state, action) => {
        state.guestUserLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearBannerState, triggerLoginPopup } = authenticationSlice.actions;

export default authenticationSlice.reducer;
